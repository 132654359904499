import { extendTheme } from '@chakra-ui/react'
import { colors } from './colors'
import components from './components'
import { fontSizes, fonts, fontWeights, letterSpacings, lineHeights } from './typography'
export const theme = extendTheme({
    styles: {
        global: (props) => ({
            body: {
                bg: '#fffff',
            },
        }),
    },
    config: {
    },
    colors,
    components,
    fontSizes,
    fonts,
    fontWeights,
    letterSpacings,
    lineHeights,
})