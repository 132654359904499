export const colors = {
    primary: '#b4914c',
    gold:{
        100:"#fff6d8",
        200:"#fff6d8",
        300:"#fff6d8",
        400:"#fff6d8",
        500:"#fff6d8",
        600:"#AB8000",
    },
    danger: '#dd0909',
    success: '#09dd2c',
    warning: '#dd9d09',
    info: '#495057',
    dark: {
        100: '#292e32',
        200: '#212529',
    }
}