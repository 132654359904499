import { Box } from '@chakra-ui/react';
import Contactus from '../layouts/Contactus';
// import ImportantBlogs from '../layouts/ImportantBlogs';
import Programs from '../layouts/Programs';
import Intro from '../layouts/Intro';
import Ourvision from '../layouts/Ourvision';
import Passion from '../layouts/Passion';
import WhyMarmani from '../layouts/WhyMarmani';
import { useEffect } from 'react';

// const Intro = lazy(() => import('../layouts/Intro'))
// const Ourvision = lazy(() => import('../layouts/Ourvision'))
// const Passion = lazy(() => import('../layouts/Passion'))
// const WhyMarmani = lazy(() => import('../layouts/WhyMarmani'))
// const Blogs = lazy(() => import('../layouts/Blogs'))
// const Header = lazy(() => import('../layouts/Header'))
// const Footer = lazy(() => import('../layouts/Footer'))
export default function Home() {
    useEffect(() => {
        window.scrollTo(0, 1)
      }, [])
    return (
        <>
            <Box minH={'87.7vh'}>
                <Intro />
            </Box>
            <Box minH={'50vh'}>
                <Passion />
            </Box>
            <Box mt={'30px'} minH={'30vh'}>
                <Ourvision />
            </Box>
            <Box bg={'white'} minH={'50vh'}>
                <WhyMarmani />
            </Box>
            {/* <Box>
                <ImportantBlogs />
            </Box> */}
            <Box>
                <Programs />
            </Box>
            <Box>
                <Contactus />
            </Box>
        </>
    )
}