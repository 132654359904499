import {
    Box,
    Button,
    Flex,
    FormControl,
    FormErrorMessage,
    Heading,
    Input,
    Stack,
    Textarea
} from "@chakra-ui/react";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import Model from "../components/Model";
import LocaleContext from "../context/LocaleContext";
import styles from '../styles/Contactus.css';
const Contactus = () => {
    const { locale } = useContext(LocaleContext);
    const { t } = useTranslation();


    const onSubmit = (data) => {
        //   setIsLoading(true)
        //   delete data.categoryIdsTemp
        //   data.phone="+" + data.phone
        //   RESERVATION.reserve(data).then((res) => {
        //     reset({ name: "", email: "", phone: "", carType: "", visitDay: "", categoryIdsTemp: [], category: [] })
        //     document.getElementById('reserve').style.background = '#54FFA3';
        //     setButtonText(t('contactus.successText'))
        //   }).catch((err) => {
        //     showErrorToast(err.response.data.message)
        //   }).finally(() => {
        //     setIsLoading(false);
        //     setTimeout(()=>{setButtonText(t("contactus.reseveACall"));document.getElementById('reserve').style.background = '#b4914c';},[3000])})
    }

    // useEffect(()=>{
    //   setButtonText(t('contactus.reseveACall'))
    //   // eslint-disable-next-line react-hooks/exhaustive-deps
    // },[locale])
    return (
        <Box
            overflow={locale === "en" ? "" : "hidden"}
            id="contactus"
            position={"relative"}
        >

            <Box
                overflow={locale === "ar" ? "" : "hidden"}
                position={"relative"}
                paddingInline={["1rem", "2rem", "4rem", "5rem", "7rem"]}
                paddingBlock={"3rem"}
                backgroundImage={'url(/assets/images/background.jpg)'}
                w="100%"
                backgroundSize={"100% 100%"}
                backgroundRepeat={"no-repeat"}
                h="fit-content"
            >
                <Heading
                    color={"primary"}
                    fontSize={["35px", "35px", "35px", "40px", "40px"]}
                    textAlign={'center'}
                    fontWeight={"semibold"}
                    mb={"20px"}
                >
                    {t("contactus.title")}
                </Heading>
                <Flex
                    position={"relative"}
                    zIndex={2}
                    flexWrap={"wrap"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                >
                    <Box flex={["100%", "100%", "50%", "50%", "50%"]}>
                        <form onSubmit={onSubmit}>
                            <Stack
                                className={styles.formContainer}
                                spacing={"10"}
                            >
                                <FormControl
                                    maxW={["100%", "100%", "80%", "80%", "80%"]}
                                >
                                    <Input
                                        placeholder={t("contactus.form.name")}
                                        borderRadius={"0px"}
                                        outline={"none"}
                                        boxShadow={"none !important"}
                                        _focusVisible={{ borderColor: "primary" }}
                                        fontSize={"15px"}
                                        bg="transparent"
                                        borderLeft={"none"}
                                        borderTop="none"
                                        borderRight={"none"}
                                        borderColor={'blackAlpha.600'}
                                        fontWeight="300"
                                        type="text"
                                    />
                                    <FormErrorMessage>
                                    </FormErrorMessage>
                                </FormControl>
                                <FormControl
                                    maxW={["100%", "100%", "80%", "80%", "80%"]}

                                >
                                    <Input
                                        placeholder={t("contactus.form.email")}
                                        borderRadius={"0px"}
                                        outline={"none"}
                                        boxShadow={"none !important"}
                                        _focusVisible={{ borderColor: "primary" }}
                                        fontSize={"15px"}
                                        bg="transparent"
                                        borderLeft={"none"}
                                        borderTop="none"
                                        borderRight={"none"}
                                        borderColor={'blackAlpha.600'}
                                        fontWeight="300"
                                        type="email"
                                    />
                                    <FormErrorMessage>
                                    </FormErrorMessage>
                                </FormControl>
                                <FormControl
                                    maxW={["100%", "100%", "80%", "80%", "80%"]}

                                >
                                    <Input
                                        maxLength={15}
                                        placeholder={t("contactus.form.phoneNumber")}
                                        borderRadius={"0px"}
                                        outline={"none"}
                                        boxShadow={"none !important"}
                                        _focusVisible={{ borderColor: "primary" }}
                                        fontSize={"15px"}
                                        bg="transparent"
                                        borderLeft={"none"}
                                        borderTop="none"
                                        borderRight={"none"}
                                        borderColor={'blackAlpha.600'}
                                        fontWeight="300"
                                        type="number"

                                    />
                                    <FormErrorMessage>

                                    </FormErrorMessage>
                                </FormControl>

                                <FormControl
                                    maxW={["100%", "100%", "80%", "80%", "80%"]}

                                >
                                    <Textarea
                                        placeholder={t("contactus.form.message")}
                                        borderRadius={"0px"}
                                        outline={"none"}
                                        boxShadow={"none !important"}
                                        _focusVisible={{ borderColor: "primary" }}
                                        fontSize={"15px"}
                                        bg="transparent"
                                        borderLeft={"none"}
                                        borderTop="none"
                                        borderRight={"none"}
                                        borderColor={'blackAlpha.600'}
                                        fontWeight="300"
                                    />
                                    <FormErrorMessage>

                                    </FormErrorMessage>
                                </FormControl>

                                <Button
                                    id='reserve'
                                    dir="rtl"
                                    loadingText={t("contactus.reseveACall")}
                                    maxW="220px"
                                    mb="20px"
                                    paddingBlock={[
                                        "1rem",
                                        "1.2rem",
                                        "1.5rem",
                                        "1.8rem",
                                        "1.8rem",
                                    ]}
                                    _hover={{ bg: "#a98133" }}
                                    color={"white"}
                                    bg={"primary"}
                                    fontWeight={"bold"}

                                    fontSize={["15px", "15px", "17px", "20px", "20px"]}
                                    type="submit"
                                >
                                    {t("contactus.reseveACall")}
                                </Button>
                            </Stack>
                        </form>
                    </Box>
                    <Flex
                        flexDirection={["row", "row", "column", "column", "column"]}
                        flex={["100%", "100%", "50%", "50%", "50%"]}
                        justifyContent={"center"}
                        alignItems={"center"}
                        gap={"40px"}
                        position={'relative'}
                    >
                        <Box zIndex={'2'} position={'absolute'} top={'0'} left={'0'} w={'100%'} h={'100%'}/>
                        <Model />
                    </Flex>
                </Flex>
            </Box>
        </Box>
    );
};
export default Contactus;
