import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js'
import { useEffect } from 'react'

const Model = () => {
  useEffect(() => {

    /**
     * Base
     */
    // Debug

    // Canvas
    const canvas = document.querySelector('canvas.webgl')

    // Scene
    const scene = new THREE.Scene()

    /**
     * Lights
     */
    const ambientLight = new THREE.AmbientLight(0xffffff, 3)
    scene.add(ambientLight)

    const directionalLight = new THREE.DirectionalLight(0xffffff, 2)
    directionalLight.castShadow = true
    directionalLight.shadow.mapSize.set(512, 512)
    directionalLight.shadow.camera.far = 0
    directionalLight.shadow.camera.left = -1
    directionalLight.shadow.camera.top = 1
    directionalLight.shadow.camera.right = 1
    directionalLight.shadow.camera.bottom = -1
    scene.add(directionalLight)

    /**
     * Sizes
     */
    const sizes = {
      width: window.innerWidth < 500 ? window.innerWidth - 50 : 500,
      height: window.innerHeight < 500 ? window.innerHeight : 450
    }

    window.addEventListener('resize', () => {
      // Update sizes
      sizes.width = window.innerWidth < 500 ? window.innerWidth - 50 : 500
      sizes.height = window.innerHeight < 500 ? window.innerHeight : 450

      // Update camera
      camera.aspect = sizes.width / sizes.height
      camera.updateProjectionMatrix()

      // Update renderer
      renderer.setSize(sizes.width, sizes.height)
      renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
    })

    /**
     * Camera
     */
    // Base camera
    let model
    const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)
    camera.position.set(0, 0.4, 0)
    scene.add(camera)

    /**
 * Models
 */
    const dracoLoader = new DRACOLoader()
    dracoLoader.setDecoderPath('/assets/draco/')

    const gltfLoader = new GLTFLoader()
    gltfLoader.setDRACOLoader(dracoLoader)


    gltfLoader.load(
      '/assets/marmani.glb',
      (gltf) => {
        model = gltf.scene;
        model.rotation.y = Math.PI;
        // Apply a material to the model
        const material = new THREE.MeshStandardMaterial({
          color: '#b4914c', // Base color
          metalness: 0.5,  // Metalness (0-1)
          roughness: 0.5   // Roughness (0-1)
        });

        scene.add(model);

        model.traverse((child) => {
          if (child.isMesh) {
            child.material = material;
          }
        });
      }
    )
    // Controls
    // Set up OrbitControls
    const controls = new OrbitControls(camera, canvas);
      controls.enableRotate = false
    // Customize OrbitControls to move only on X-axis
    controls.enablePan = false; // Disable panning
    controls.enableZoom = false; // Disable zooming
    // Set damping to control the speed of movement
    controls.enableDamping = true;
    controls.dampingFactor = 0.01;

    /**
     * Renderer
     */
    const renderer = new THREE.WebGLRenderer({
      canvas: canvas,
      antialias: true,
      alpha: true
    })
    renderer.shadowMap.enabled = true
    renderer.shadowMap.type = THREE.PCFSoftShadowMap
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
    /**
     * Animate
     */
    const clock = new THREE.Clock()
    // let previousTime = 0


    const tick = () => {
      const elapsedTime = clock.getElapsedTime()
      // const deltaTime = elapsedTime - previousTime
      // previousTime = elapsedTime

      if (model) {
        model.rotation.z = elapsedTime 
      }

      // Update controls
      // controls.update()

      // Render
      renderer.render(scene, camera)

      // Call tick again on the next frame
      window.requestAnimationFrame(tick)
    }

    tick()

  }, [])

  return <canvas style={{ background: 'transparent', width: '100%', height: '100%' }} className="webgl"></canvas>
}
export default Model