import { Box, Flex, Image, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

const WhyMarmani = () => {
    const { t } = useTranslation();

    return (
        <>
            <Flex overflow={'hidden'} pb={'20px'} minH={'70vh'} position={'relative'} flexDir={'column'} justifyContent={'center'} alignItems={'center'}>
                <Box data-aos="fade-left" data-aos-delay="500" data-aos-duration="1000" mb={'50px'} w={'100%'} position={'relative'}>
                    <Flex justifyContent={'center'} alignItems={'center'} background={'white'} color={'primary'} borderBlock={'4px solid #b4914c'} h={'100px'} w={'100%'} textAlign={'center'}>
                        <Text mb={'10px'} fontSize={['30px','40px','50px','60px','60px']} fontWeight={'bold'}>{t('whyMarmani.heading')}</Text>
                    </Flex>
                </Box>
                <Flex fontSize={'12px'} flexWrap={'wrap'} justifyContent={'center'} alignItems={'start'} marginInline={'2rem'} gap={'40px'}>
                    <Flex flex={['100%', '47%', '47%', '30%', '30%']} data-aos="fade-left" data-aos-delay="500" data-aos-duration="1000">
                        <Flex flexDir={'column'} w={'100%'} border={'2px solid #f9f9f9'} _hover={{ border: '2px solid #b4914c' }} h={'220px'} transition={'border 0.5s linear'} p={'10px'} gap={'15px'} borderRadius={'7px'} boxShadow={'lg'} justifyContent={'center'} alignItems={'center'}>
                            <Image src="/assets/vectors/why marmani/easy-learn.svg" alt="" w={'50px'} />
                            <Text fontWeight={'bold'} fontSize={['15px','15px','17px','18px','19px']} color={'primary'}>{t('whyMarmani.simplifiedLearning.title')}</Text>
                            <Text textAlign={'center'} fontSize={['13px','13px','13px','14px','14px']}>
                                {t('whyMarmani.simplifiedLearning.body')}
                            </Text>
                        </Flex>
                    </Flex>
                    <Flex flex={['100%', '47%', '47%', '30%', '30%']} data-aos="fade-down" data-aos-delay="500" data-aos-duration="1000">
                        <Flex flexDir={'column'} w={'100%'} border={'2px solid #f9f9f9'} _hover={{ border: '2px solid #b4914c' }} h={'220px'} transition={'border 0.5s linear'} p={'10px'} gap={'15px'} borderRadius={'7px'} boxShadow={'lg'} justifyContent={'center'} alignItems={'center'}>
                            <Image src="/assets/vectors/why marmani/treasure.svg" alt="" w={'60px'} />
                            <Text fontWeight={'bold'} fontSize={['15px','15px','17px','18px','19px']} color={'primary'}>{t('whyMarmani.competitivePrices.title')}</Text>
                            <Text textAlign={'center'} fontSize={['13px','13px','13px','14px','14px']}>
                                {t('whyMarmani.competitivePrices.body')}
                            </Text>
                        </Flex>
                    </Flex>
                    <Flex flex={['100%', '47%', '47%', '30%', '30%']} data-aos="fade-right" data-aos-delay="500" data-aos-duration="1000">
                        <Flex flexDir={'column'} w={'100%'} border={'2px solid #f9f9f9'} _hover={{ border: '2px solid #b4914c' }} h={'220px'} transition={'border 0.5s linear'} p={'10px'} gap={'15px'} borderRadius={'7px'} boxShadow={'lg'} justifyContent={'center'} alignItems={'center'}>
                            <Image src="/assets/vectors/why marmani/live.svg" alt="" w={'60px'} />
                            <Text fontWeight={'bold'} fontSize={['15px','15px','17px','18px','19px']} color={'primary'}>{t('whyMarmani.interactiveLiveLessons.title')}</Text>
                            <Text textAlign={'center'} fontSize={['13px','13px','13px','14px','14px']}>
                                {t('whyMarmani.interactiveLiveLessons.body')}
                            </Text>
                        </Flex>
                    </Flex>
                    <Flex flex={['100%', '47%', '47%', '30%', '30%']} data-aos="fade-left" data-aos-delay="500" data-aos-duration="1000">
                        <Flex flexDir={'column'} w={'100%'} border={'2px solid #f9f9f9'} _hover={{ border: '2px solid #b4914c' }} h={'220px'} transition={'border 0.5s linear'} p={'10px'} gap={'15px'} borderRadius={'7px'} boxShadow={'lg'} justifyContent={'center'} alignItems={'center'}>
                            <Image src="/assets/vectors/why marmani/content.svg" alt="" w={'50px'} />
                            <Text fontWeight={'bold'} fontSize={['15px','15px','17px','18px','19px']} color={'primary'}>{t('whyMarmani.customContent.title')}</Text>
                            <Text textAlign={'center'} fontSize={['13px','13px','13px','14px','14px']}>
                                {t('whyMarmani.customContent.body')}
                            </Text>
                        </Flex>
                    </Flex>
                    <Flex flex={['100%', '47%', '47%', '30%', '30%']} data-aos="fade-up" data-aos-delay="500" data-aos-duration="1000">
                        <Flex flexDir={'column'} w={'100%'} border={'2px solid #f9f9f9'} _hover={{ border: '2px solid #b4914c' }} h={'220px'} transition={'border 0.5s linear'} p={'10px'} gap={'15px'} borderRadius={'7px'} boxShadow={'lg'} justifyContent={'center'} alignItems={'center'}>
                            <Image src="/assets/vectors/why marmani/support.svg" alt="" w={'50px'} />
                            <Text fontWeight={'bold'} fontSize={['15px','15px','17px','18px','19px']} color={'primary'}>{t('whyMarmani.continuousStudentSupport.title')}</Text>
                            <Text textAlign={'center'} fontSize={['13px','13px','13px','14px','14px']}>
                                {t('whyMarmani.continuousStudentSupport.body')}
                            </Text>
                        </Flex>
                    </Flex>
                    <Flex flex={['100%', '47%', '47%', '30%', '30%']} data-aos="fade-right" data-aos-delay="500" data-aos-duration="1000">
                        <Flex flexDir={'column'} w={'100%'} border={'2px solid #f9f9f9'} _hover={{ border: '2px solid #b4914c' }} h={'220px'} transition={'border 0.5s linear'} p={'10px'} gap={'15px'} borderRadius={'7px'} boxShadow={'lg'} justifyContent={'center'} alignItems={'center'}>
                            <Image src="/assets/vectors/why marmani/discount.svg" alt="" w={'55px'} />
                            <Text fontWeight={'bold'} fontSize={['15px','15px','17px','18px','19px']} color={'primary'}>{t('whyMarmani.variousCourseOffers.title')}</Text>
                            <Text textAlign={'center'} fontSize={['13px','13px','13px','14px','14px']}>
                                {t('whyMarmani.variousCourseOffers.body')}
                            </Text>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
        </>
    )
}
export default WhyMarmani;