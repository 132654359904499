import {
    Flex,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    useDisclosure
} from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import LocaleContext from "../context/LocaleContext";
import { PROGRAM } from "../services/program";

export default function Dropdown({ button,bg }) {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [programs, setPrograms] = useState([]);
    const { locale } = useContext(LocaleContext)
    const navigate = useNavigate();
    useEffect(() => {
        PROGRAM.getPrograms(locale).then(res => {
            setPrograms(res.data.data)
        })
    }, [locale])

    return (
        <Menu isOpen={isOpen} gutter={1}>
            <MenuButton
                variant="ghost"
                mx={1}
                py={[1, 2, 2]}
                px={4}
                borderRadius={5}
                _hover={{ color: '#b4914c' }}
                aria-label="Courses"
                fontWeight="normal"
                onMouseEnter={onOpen}
                onMouseLeave={onClose}
            >
                <Flex gap={'10px'}>
                    {button}
                    {isOpen ? <i className="bi bi-chevron-up"></i> : <i className="bi bi-chevron-down"></i>}
                </Flex>
            </MenuButton>
            <MenuList bg={bg} onMouseEnter={onOpen} onMouseLeave={onClose}>
                {programs.map((program) => (
                    <MenuItem bg={bg} _hover={{bg:'#b4914c',color:'white'}} onClick={() => navigate(`/program/${program.slug}`)} >{program.translations[0].name}</MenuItem>
                ))}
            </MenuList>
        </Menu>
    )
}