import { Box, Flex, Text } from "@chakra-ui/react";
import '../styles/ourvision.css'
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import LocaleContext from "../context/LocaleContext";
const Ourvision = () => {
    const { t } = useTranslation();
    const { locale } = useContext(LocaleContext)
    return (
        <Flex flexDir={['column', 'column', 'row', 'row', 'row']} justifyContent={'center'} alignItems={'center'} overflow={'hidden'}>
            <Flex className={'container'} minW={'20%'} w={['100%', '100%', '50%', '50%', '50%']} h={'70vh'} transition={'0.7s'} justifyContent={'center'} position={'relative'} _hover={{ w: '100%' }} bgImage={'url(/assets/images/ourvision.jpg)'} backgroundRepeat={'no-repeat'} backgroundSize={'cover'} backgroundAttachment={'fixed'}>
                <Box bg='linear-gradient(#000000 60%,#b4914c)' position={'absolute'} opacity={'0.6'} top={'0'} left={'0'} w={'100%'} h={'70vh'}></Box>
                <div data-aos="fade-up" data-aos-delay="500" data-aos-duration="1000">
                    <Flex w={'100%'} h={'100%'} className={'textContainer'} transition={'all 1s'} flexDir={'column'} zIndex={'1'} position={'relative'} justifyContent={'end'} alignItems={'center'}>
                        <Text position={'absolute'} top={'50%'} left={'50%'} className="visiontitleText" transition={'top 0.4s'} transform={'translate(-50%,-50%)'} textAlign={'center'} color='primary' fontWeight={'bold'} fontSize={'55px'}>
                            {t('vision.ourvision.title')}
                        </Text>
                        {locale === 'ar' ? (
                            <Text p={'10px'} transform={'translateY(-20px)'} position={'relative'} w={['100%', '100%', '400px', '600px', '600px']} className={'visiontext'} zIndex={'0'} transition={'all 0.3s ease 0.2s'} opacity={0} textAlign={'start'} fontSize={['11px', '12px', '13px', '14px', '15px']} fontWeight={'medium'} color={'white'}>
                                رؤيتنا تتوجه نحو إحداث <span style={{ fontWeight: 'bold' }}>ثورة تعليمية</span> بجعل المعرفة <span style={{ color: '#b4914c' }}> في متناول الجميع وبأسعار تنافسية.</span><br></br>
                                ونتطلع أن نكون معهداٌ رائداً في تقديم دورات عالية القيمة المعرفية و المفيدة للمتدربين القانونيين والدبلوماسيين والمهندسين والموظفين وأصحاب الأعمال والفنيين بمختلف الاختصاصات التكنولوجية والعلاقات الأسرية وتطوير مهارات الأطفال.<br></br>
                                ان التزامنا بجودة خدماتنا واستمرار تطويرها يعطي دفعاٌ قوياً يمكَن متدربينا من ارتقاء امكانياتهم نحو المزيد من الرفعة.<br></br><br></br>
                                <span style={{ color: '#b4914c', fontWeight: 'bolder' }}>تتصور مارماني عالماً لا يعرف فيه التعليم حدوداً، وحيث توجد المعرفة في متناول الجميع توجد مارماني، ويمتد تأثيرها في جميع أنحاء دولة الامارات العربية المتحدة.</span>
                            </Text>
                        ) : (
                            <Text p={'10px'} transform={'translateY(-20px)'} position={'relative'} w={['100%', '100%', '400px', '600px', '600px']} className={'visiontextEn'} zIndex={'0'} transition={'all 0.3s ease 0.2s'} opacity={0} textAlign={'start'} fontSize={['11px', '12px', '13px', '14px', '15px']} fontWeight={'medium'} color={'white'}>
                                Our vision is directed towards creating an <span style={{ fontWeight: 'bold' }}> educational revolution </span>by making knowledge <span style={{ color: '#b4914c' }}> accessible to everyone at competitive prices.</span><br></br>
                                We aspire to be a leading institute in providing courses of high cognitive value and usefulness to our legal students, diplomats, engineers, employees, business owners, and technicians in various technological specializations, family relations, and developing children’s skills.<br></br>
                                Our commitment to the quality of our services and their continued development gives an impetus that enables our students to advance their potential towards greater advancement.<br></br><br></br>
                                <span style={{ color: '#b4914c', fontWeight: 'bolder' }}> Marmani envisions a world where education knows no borders, and where knowledge is accessible to everyone. Marmani exists, and its influence extends throughout the UAE.</span>
                            </Text>
                        )}
                    </Flex>
                </div>
            </Flex>

            <Flex className={'container'} minW={'20%'} w={['100%', '100%', '50%', '50%', '50%']} h={'70vh'} transition={'0.7s'} justifyContent={'center'} position={'relative'} _hover={{ w: '100%' }} bgImage={'url(/assets/images/ourmission.jpg)'} backgroundRepeat={'no-repeat'} backgroundSize={'cover'} backgroundAttachment={'fixed'}>
                <Box bg='linear-gradient(#000000 60%, #2a4db5)' position={'absolute'} opacity={'0.6'} top={'0'} left={'0'} w={'100%'} h={'70vh'}></Box>
                <div data-aos="fade-up" data-aos-delay="500" data-aos-duration="1000">
                    <Flex w={'100%'} h={'100%'} className={'textContainer'} transition={'all 1s'} flexDir={'column'} zIndex={'1'} position={'relative'} justifyContent={'end'} alignItems={'center'}>
                        <Text position={'absolute'} top={'50%'} left={'50%'} transform={'translate(-50%,-50%)'} textAlign={'center'} color='primary' fontWeight={'bold'} transition={'top 0.4s'} className={locale === 'ar' ? "missiontitleTextAr" : "missiontitleTextEn"} fontSize={'55px'}>
                            {t('vision.ourmission.title')}
                        </Text>
                        {locale === 'ar' ? (
                            <Text p={'10px'} transform={'translateY(-50px)'} position={'relative'} w={['100%', '100%', '400px', '600px', '600px']} className={'missiontext'} zIndex={'0'} transition={'all 0.3s ease 0.2s'} opacity={0} textAlign={'start'} fontSize={['10px', '12px', '13px', '15px', '15px']} fontWeight={'medium'} color={'white'}>
                                <span style={{ fontWeight: 'bolder', fontSize: '14px' }}>تقديم خبراتنا التعليمية وعصارة معرفتنا لطلابنا مع التركيز المستمر على البساطه والسهولة.</span><br></br>
                                ونهدف لتزويد المتدربين بخبرات تعليمية عملية وفعّالة من خلال دورات تفاعلية<span style={{ fontWeight: 'bolder' }}> وبأسعار معقولة</span>.<br></br><br></br>
                                <span style={{ color: '#b4914c', fontWeight: 'bolder' }}>تلتزم مارماني بجعل المتدربين يشعرون بأنهم أشخاصاً أخرين بعد انتهاء دورتهم التدريبية، مزودين بشهادات معتمدة كونهم تلقوا تعليماً استثنائياً أضاف  لهم معرفة ذات فائدة جديدة وحديثة.</span>
                            </Text>
                        ) : (
                            <Text p={'10px'} transform={'translateY(-50px)'} position={'relative'} w={['100%', '100%', '400px', '600px', '600px']} className={'missiontext'} zIndex={'0'} transition={'all 0.3s ease 0.2s'} opacity={0} textAlign={'start'} fontSize={['10px', '12px', '13px', '15px', '15px']} fontWeight={'medium'} color={'white'}>
                                <span style={{ fontWeight: 'bolder', fontSize: '14px' }}>Providing our educational experiences and the essence of our knowledge to our students with a constant focus on simplicity and ease.</span><br></br>
                                We also aim to provide our students with practical and effective learning experiences through interactive and <span style={{ fontWeight: 'bolder' }}>affordable courses.</span><br></br><br></br>
                                <span style={{ color: '#b4914c', fontWeight: 'bolder' }}>Our commitment is to support our students in their pursuit of outstanding and exceptional knowledge. Marmani is committed to making its students feel like other people after the end of their training course, because they received an exceptional education that added to them new and modern useful knowledge.</span>
                            </Text>
                        )}
                    </Flex>
                </div>
            </Flex >
        </Flex >
    )
}
export default Ourvision;