import { darken } from "@chakra-ui/theme-tools";
// Button component
const Button = {
    baseStyle: {

    },
    variants: {
        primary: {
            bg: "primary.normal",
            color: "white",
            _hover: {
                bg: darken('primary.normal', 8),
            },
        },
        secondary: {
            bg: 'secondary',
            color: 'white',
            _hover: {
                bg: darken('secondary', 5),
            },
        }
    },
    defaultProps: {
        // variant: 'primary',
    },
};

// Tabs component
const Tabs = {
    defaultProps: {},
    baseStyle: {
        tab: {
            borderBottom:'2px solid transparent',
            _selected: {
                color: 'white',
                borderColor: 'inherit',
            },
            _active:{
                bg:'none'
            }
        },
    }
};

// Input component
const Input = {
    baseStyle: {
        field: {
        },
        addon: {

        },
    },

    defaultProps: {},
};


//Select componenet
const Select = {
    baseStyle: {
        field: {}
    },
    defaultProps: {},
};
// IconButton component
const IconButton = {
    defaultProps: {},
};

// Tooltip component
const Tooltip = {
    defaultProps: {},
};

// Tooltip component
const Menu = {
    baseStyle: {
        button: {},
        list: {},
        item: {},
        groupTitle: {},
        command: {},
        divider: {},
    },
    defaultProps: {},
};

// Popover component
const Popover = {
    baseStyle: {
        popper: {},
    },

    defaultProps: {},
};

// Checkbox component
const Checkbox = {
    defaultProps: {},
};

// Radio component
const Radio = {
    baseStyle: {
        control: {},
        label: {},
        container: {}
    },
    defaultProps: {},
};

// RangeSlider component
const RangeSlider = {
    defaultProps: {},
};


// Slider component
const Slider = {
    defaultProps: {},
};

// Switch component
const Switch = {
    defaultProps: {},
};

const Label = {
    baseStyle: {
    }
}
// Textarea component
const Textarea = {
    defaultProps: {},
};

// Link component
const Link = {
    baseStyle: {
        _hover: {},
    },
    defaultProps: {},
};
const Breakpoints = {
    sm: '30em', // 480px
    md: '48em', // 768px
    lg: '62em', // 992px
    xl: '80em', // 1280px
    '2xl': '96em', // 1536px
}
const components = {
    Button: Button,
    Tabs: Tabs,
    Input: Input,
    IconButton: IconButton,
    Tooltip: Tooltip,
    Menu: Menu,
    Popover: Popover,
    Checkbox: Checkbox,
    Radio: Radio,
    RangeSlider: RangeSlider,
    Select: Select,
    Slider: Slider,
    Switch: Switch,
    Textarea: Textarea,
    Link: Link,
    FormLabel: Label,
    Breakpoints: Breakpoints
};

export default components;
