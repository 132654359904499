import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from './pages/Home';
import Program from "./pages/Program";
import Course from "./pages/Course";
// import Blogs from "./pages/Blogs/Blogs";
// import Blog from "./pages/Blogs/Blog";
import { Helmet, HelmetProvider } from "react-helmet-async";
import i18n from "./components/common/i18n";
import { useState } from "react";
import LocaleContext from "./context/LocaleContext";
import Owner from "./pages/Owner";
import Header from "./layouts/Header";
import Footer from "./layouts/Footer";

function App() {
  const [locale, setLocale] = useState(i18n.language);
  i18n.on("languageChanged", (lng) => setLocale(i18n.language));

  return (
    <HelmetProvider>
      <LocaleContext.Provider value={{ locale, setLocale }}>
        <Helmet
          htmlAttributes={{
            lang: locale,
            dir: locale === "en" ? "ltr" : "rtl",
          }}
        />
        <Router>
          <Header />
          <Routes>
            <Route path="*" element={<Home />} />

            {/*        Home         */}
            <Route path="/" element={<Home />} />

            {/*        Owner         */}
            <Route path="/owner" element={<Owner />} />

            {/*        Blogs         */}
            {/* <Route path="/blogs/:slug" element={<Blog />} /> */}
            {/* <Route path="/blogs" element={<Blogs />} /> */}

            {/*        Program         */}
            <Route path="/program/:slug" element={<Program />} />
            <Route path="/program/:programSlug/course/:courseSlug" element={<Course />} />
          </Routes>
          <Footer />

        </Router>
      </LocaleContext.Provider>
    </HelmetProvider>
  );
}

export default App;
