import { Box, Flex, Image, Link, Stack, Text } from "@chakra-ui/react";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import LocaleContext from "../context/LocaleContext";
import Dropdown from "../components/Dropdown";

const Footer = () => {
  const { locale } = useContext(LocaleContext);
  const { t } = useTranslation()
  const navigate = useNavigate();
  return (
    <Box bg={'blackAlpha.900'} p={'2'}>
      <Flex position={'relative'} flexDirection={['column', 'column', 'row', 'row', 'row']} gap={['20px', '20px', '0px', '0px', '0px']} minH={'180px'} h='100%' w='100%' justifyContent={['center', 'center', 'space-between', 'space-between', 'space-between']} alignItems={'center'} paddingInline={['2rem', '2rem', '3rem', '3rem', '3rem']}>
        <Box p={'10px'} borderRight={locale === 'en' ? ['0px solid rgba(255,255,255,0.1)', '0px solid rgba(255,255,255,0.1)', '1px solid rgba(255,255,255,0.1)', '1px solid rgba(255,255,255,0.1)', '1px solid rgba(255,255,255,0.1)'] : ''} borderLeft={locale === 'ar' ? ['0px solid rgba(255,255,255,0.1)', '0px solid rgba(255,255,255,0.1)', '1px solid rgba(255,255,255,0.1)', '1px solid rgba(255,255,255,0.1)', '1px solid rgba(255,255,255,0.1)'] : ''}>
          <Flex mt={'10px'} w={'100%'} justifyContent={'space-between'} alignItems={'center'}>
            <Box w={'60px'} h={'60px'}>
              <Image w={'100%'} h={'100%'} src={'/assets/vectors/logo.svg'} alt='' />
            </Box>
            <Stack spacing={'2'}>
              <Text color={'primary'} fontSize={'15px'} lineHeight={'20px'} fontWeight={locale === 'en' ? '400' : '700'}>{t('header.marmani')}</Text>
              <Box w={'120px'} lineHeight={'8px'}><Text color={'white'} fontSize={'9px'} fontWeight='600'>{t('header.logoText')}</Text></Box>
            </Stack>
          </Flex>
        </Box>
        <Flex mt={'10px'} mb={'10px'} justifyContent={'center'} alignItems={'center'} gap={['20px', '20px', '40px', '50px', '50px']}>
          <Text onClick={() => navigate('/owner')} fontSize={'16px'} cursor={'pointer'} color={'white'}>{t('header.manager')}</Text>
          <Text onClick={() => navigate('/')} fontSize={'16px'} cursor={'pointer'} color={'white'}>{t('header.aboutus')}</Text>
          <Box color={'white'}>
          <Dropdown bg={'black'} button={<Text>{t("header.courses")}</Text>} />
          </Box>
          {/* <Text onClick={() => navigate('/courses')} fontSize={'16px'} cursor={'pointer'} color={'white'}>{t('header.courses')}</Text>
          <Text onClick={() => navigate('/blogs')} fontSize={'16px'} cursor={'pointer'} color={'white'}>{t('header.blogs')}</Text> */}
        </Flex>
        <Box dir="ltr" p={'30px'} borderRight={locale === 'ar' ? ['0px solid rgba(255,255,255,0.1)', '0px solid rgba(255,255,255,0.1)', '1px solid rgba(255,255,255,0.1)', '1px solid rgba(255,255,255,0.1)', '1px solid rgba(255,255,255,0.1)'] : ''} borderLeft={locale === 'en' ? ['0px solid rgba(255,255,255,0.1)', '0px solid rgba(255,255,255,0.1)', '1px solid rgba(255,255,255,0.1)', '1px solid rgba(255,255,255,0.1)', '1px solid rgba(255,255,255,0.1)'] : ''}>
          <Stack spacing={'2'}>
            <Flex justifyContent={'center'} alignItems={'center'} gap='5px'>
              <i style={{ color: '#b4914c' }} className={'bi bi-whatsapp'}></i>
              <Link target="_blank" href="https://wa.me/971544540170"> <Text color={'white'}>+971 54 454 0170</Text></Link>
            </Flex>

            <Flex justifyContent={'center'} alignItems={'center'} gap='10px'>
              <i style={{ color: '#b4914c' }} className={'bi bi-envelope-fill'}></i>
              <Link target="_blank" href="mailto:info@marmani.ae"> <Text fontSize={'16px'} cursor={'pointer'} color={'white'}>info@marmani.ae</Text></Link>
            </Flex>
          </Stack>
        </Box>
      </Flex >
      <Text mt={'30px'} textAlign={'center'} color={'white'} fontWeight={'medium'} fontSize={['12px', '13px', '14px', '15px', '15px']}>{t('footer.marmani')} {new Date().getFullYear()} © . {t('footer.rights')}</Text>

    </Box >
  )
};

export default Footer;
