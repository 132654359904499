import { Box, Flex, Image, Text, useMediaQuery } from "@chakra-ui/react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const Owner = () => {
    const [maxwidth768] = useMediaQuery('(max-width: 768px)', {
        ssr: true,
        fallback: false, // return false on the server, and re-evaluate on the client side
    })
    const { t } = useTranslation()
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <>
            <Box w={'100%'} h='87.5vh' overflow={maxwidth768 ? 'auto' : 'hidden'}>
                <Flex w={'100%'} flexDir={maxwidth768 ? 'column' : 'row'} paddingInline={['1rem', '2rem', '3rem', '3rem', '3rem']} alignItems={'start'}>
                    <Flex mt={'70px'} w={'100%'} minW={'50%'} maxW={'100%'} flex={['100%', '100%', '50%', '50%', '50%']} flexDir={'column'}>
                        <Flex data-aos="fade-left" data-aos-duration="1000">
                            <Text mb={'10px'} color='primary' fontWeight={'bold'} fontSize={'25px'}>{t('owner.title')}
                            </Text>
                        </Flex>
                        <Flex data-aos="fade-left" data-aos-delay="100" data-aos-duration="1300">
                            <Text fontSize={['15px', '15px', '13px', '15px', '18px']} fontWeight={'medium'} color={'black'}>
                               {t('owner.body')}
                            </Text>
                        </Flex>
                    </Flex>
                    <Flex flex={['100%', '100%', '50%', '50%', '50%']} data-aos="fade-right" data-aos-duration="1000" borderRadius={'20px'} overflow={'hidden'}>
                        <Image w={'100%'} src="/assets/images/owner.png" alt="" />
                    </Flex>
                </Flex>
            </Box>
        </>
    )
}
export default Owner;