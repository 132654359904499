import { Box, Flex, Image, Text } from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import LocaleContext from "../context/LocaleContext";
import { PROGRAM } from "../services/program";
import '../styles/Blogs.css';
const Programs = () => {
    const [programs, setPrograms] = useState([]);
    const { locale } = useContext(LocaleContext)
    const navigate = useNavigate();
    useEffect(() => {
        PROGRAM.getPrograms(locale).then(res => {
            setPrograms(res.data.data)
        })
    }, [locale])
    const { t } = useTranslation();

    return (
        <>
            <div id="services" data-aos="fade-down" data-aos-duration="1000">
                <Text mt={'50px'} mb={'50px'} color={'primary'} fontWeight={'bold'} fontSize={'44px'} textAlign={'center'}>{t('programs.heading')}</Text>
            </div>
            <Flex mb={'50px'} gap={'20px'} flexWrap={'wrap'} justifyContent={'center'} alignItems={'center'} marginInline={['10px', '15px', '30px', '50px', '50px']}>
                {programs?.map((program) => (
                    <Flex onClick={() => navigate(`/program/${program.slug}`)} cursor={'pointer'} flex={['100%', '100%', '47%', '30%', '30%']} maxW={'399px'} w={'100%'} overflow={'hidden'} boxShadow={'xl'} borderRadius={'3px'} >
                        <Flex alignItems={'center'} className={'blog'} flexDir={'column'} w={'100%'} h={'100%'} _hover={{ bg: 'primary', color: 'white' }} transition={'all 0.4s '} bg={'white'} gap={'10px'} boxShadow={'xl'}>
                            <Box w={'100%'}>
                                <Flex position={'relative'} w={'100%'} overflow={'hidden'} h={'250px'}>
                                    <Image alt="" transition={'transform 0.6s ease-in-out'} src={`${process.env.REACT_APP_BASE_URL}/uploads/program/${program?.image}`} w={'100%'} h={'100%'} objectFit={'cover'} />
                                    <Box bg='black' position={'absolute'} opacity={'0.5'} top={'0'} left={'0'} w={'100%'} h={'100%'}></Box>
                                </Flex>
                            </Box>
                            <Box m={'auto'} p={'10px'}>
                                <Text mb={'5px'} noOfLines={'3'} fontWeight={'bold'} fontSize={'14px'}>
                                    {program.translations[0].name}
                                </Text>
                            </Box>
                        </Flex>
                    </Flex>
                ))}
            </Flex >
        </>
    )
}
export default Programs;