import { Box, Flex, Image, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

const Passion = () => {
    const { t } = useTranslation()
    return (
        <>
            <div id="services" data-aos="fade-down" data-aos-duration="1000">
                <Text mt={'50px'} mb={'50px'} color={'primary'} fontWeight={'bold'} fontSize={['24px', '24px', '34px', '44px', '44px']} textAlign={'center'}>{t('passion.heading')}</Text>
            </div>
            <Flex flexWrap={'wrap'} justifyContent={'center'} gap={'20px'} w={'100%'} m={'auto'} alignItems={'center'}>
                <div style={{ padding: '10px' }} data-aos="fade-down" data-aos-delay="700" data-aos-anchor-placement="top-center" data-aos-duration="1000">
                    <Flex flex={['100%', '47%', '47%', '30%', '30%']} overflow={'hidden'} boxShadow={'xl'} borderRadius={'3px'} justifyContent={'space-around'} flexWrap={'wrap'} alignItems={'center'}>
                        <Flex alignItems={'center'} flexDir={'column'} maxW={'392px'} w={'100%'} bg={'white'} boxShadow={'xl'}>
                            <Box>
                                <Flex position={'relative'} maxW={'392px'} w={'100%'} h={'100%'}>
                                    <Image alt="" src="/assets/images/passion/law-students.jpg" maxW={'392px'} w={'100%'} h={'100%'} objectFit={'cover'} />
                                    <Box bg='black' position={'absolute'} opacity={'0.5'} top={'0'} left={'0'} w={'100%'} h={'100%'}></Box>
                                    <Text position={'absolute'} color={'primary'} top={'50%'} left={'50%'} transform={'translate(-50%,-50%)'} fontWeight={'bold'} fontSize={'23px'} textAlign={'center'}>{t('passion.lawStudents.title')}</Text>
                                </Flex>
                            </Box>
                            <Box m={'auto'} p={'10px'}>
                                <Text color={'black'} fontWeight={'bold'} fontSize={'13px'}>
                                    {t('passion.lawStudents.body')}
                                </Text>
                            </Box>
                        </Flex>
                    </Flex>
                </div>

                <div style={{ padding: '10px' }} data-aos="fade-down" data-aos-delay="400" data-aos-anchor-placement="top-center" data-aos-duration="1000">
                    <Flex flex={['100%', '47%', '47%', '30%', '30%']} overflow={'hidden'} boxShadow={'xl'} borderRadius={'3px'} justifyContent={'space-around'} flexWrap={'wrap'} alignItems={'center'}>
                        <Flex alignItems={'center'} flexDir={'column'} w={'100%'} maxW={'392px'} bg={'white'} boxShadow={'xl'}>
                            <Box>
                                <Flex position={'relative'} maxW={'392px'} w={'100%'} h={'100%'}>
                                    <Image alt="" src="/assets/images/passion/law-owner.jpg" maxW={'392px'} w={'100%'} h={'100%'} objectFit={'cover'} />
                                    <Box bg='black' position={'absolute'} opacity={'0.5'} top={'0'} left={'0'} w={'100%'} h={'100%'}></Box>
                                    <Text position={'absolute'} color={'primary'} top={'50%'} left={'50%'} transform={'translate(-50%,-50%)'} fontWeight={'bold'} fontSize={'23px'} textAlign={'center'}>{t('passion.lawyers.title')}</Text>
                                </Flex>
                            </Box>
                            <Box m={'auto'} p={'10px'}>
                                <Text color={'black'} fontWeight={'bold'} fontSize={'13px'}>
                                    {t('passion.lawyers.body')}
                                </Text>
                            </Box>
                        </Flex>
                    </Flex>
                </div>

                <div style={{ padding: '10px' }} data-aos="fade-down" data-aos-anchor-placement="top-center" data-aos-duration="1000">
                    <Flex flex={['100%', '47%', '47%', '30%', '30%']} overflow={'hidden'} borderRadius={'3px'} flexWrap={'wrap'} alignItems={'center'} boxShadow={'xl'}>
                        <Flex alignItems={'center'} flexDir={'column'} w={'100%'} maxW={'392px'} bg={'white'}>
                            <Box>
                                <Flex position={'relative'} maxW={'392px'} w={'100%'} h={'100%'}>
                                    <Image alt="" src="/assets/images/passion/business-owners.jpg" maxW={'392px'} w={'100%'} h={'100%'} objectFit={'cover'} />
                                    <Box bg='black' position={'absolute'} opacity={'0.5'} top={'0'} left={'0'} w={'100%'} h={'100%'}></Box>
                                    <Text position={'absolute'} color={'primary'} top={'50%'} left={'50%'} transform={'translate(-50%,-50%)'} fontWeight={'bold'} fontSize={'21px'} textAlign={'center'}>{t('passion.businessOwners.title')}</Text>
                                </Flex>
                            </Box>
                            <Box m={'auto'} p={'10px'}>
                                <Text color={'black'} fontWeight={'bold'} fontSize={'13px'}>
                                    {t('passion.businessOwners.body')}
                                </Text>
                            </Box>
                        </Flex>
                    </Flex>
                </div>
            </Flex >
        </>
    )
}
export default Passion;