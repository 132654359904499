import { Box, Button, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerOverlay, Flex, Image, Menu, MenuButton, MenuItem, MenuList, Stack, Text, useDisclosure, useMediaQuery, } from "@chakra-ui/react";
import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import i18n from "../components/common/i18n";
import LocaleContext from "../context/LocaleContext";
import '../styles/Header.css'
import Dropdown from "../components/Dropdown";
const Header = () => {
  const { locale } = useContext(LocaleContext);
  const { t } = useTranslation()
  const changeLocale = (l) => {
    if (locale !== l) {
      i18n.changeLanguage(l);
    }
  };
  const navigate = useNavigate()
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();
  const [maxwidth990] = useMediaQuery('(max-width: 990px)', {
    ssr: true,
    fallback: false, // return false on the server, and re-evaluate on the client side
  })

  const [scrollDirection, setScrollDirection] = React.useState(null);

  useEffect(() => {
    let lastScrollY = window.pageYOffset;
    const updateScrollDirection = () => {
      const scrollY = window.pageYOffset;
      const direction = scrollY > lastScrollY ? "down" : "up";
      if (direction !== scrollDirection && (scrollY - lastScrollY > 5 || scrollY - lastScrollY < -5)) {
        setScrollDirection(direction);
      }
      lastScrollY = scrollY > 0 ? scrollY : 0;
    };
    window.addEventListener("scroll", updateScrollDirection); // add event listener
    return () => {
      window.removeEventListener("scroll", updateScrollDirection); // clean up
    }
  }, [scrollDirection])

  return (
    <>
      <Flex w={"100%"} paddingInline={'30px'} h={"13vh"} bg={"white"} alignItems={'center'} justifyContent={"space-between"} position={"sticky"} zIndex={1000} top={scrollDirection === 'down' ? -100 : 0} boxShadow={'lg'} transition={'top 0.4s'} >
        <Flex onClick={() => navigate('/')} cursor={'pointer'} data-aos="fade-left" data-aos-duration="1000" alignItems={'center'}>
          <Box w={'60px'} h={'60px'}>
            <Image w={'100%'} h={'100%'} src={'/assets/vectors/logo.svg'} alt='' />
          </Box>
          <Stack spacing={'1'}>
            <Text color={'primary'} fontSize={'15px'} lineHeight={'20px'} fontWeight={locale === 'en' ? '400' : '700'}>{t('header.marmani')}</Text>
            <Box w={'120px'} lineHeight={'8px'}><Text color={'black'} fontSize={'9px'} fontWeight='600'>{t('header.logoText')}</Text></Box>
          </Stack>
        </Flex>
        {maxwidth990 && (
          <Flex justifyContent={"center"} alignItems={"center"} gap={"15px"}>
            <Image
              onClick={onOpen}
              src={'/assets/vectors/menu.svg'}
              alt=""
              w={["20px", "20px", "20px", "20px", "20px"]}
              h={["15px", "20px", "20px", "20px", "20px"]}
            />
          </Flex>
        )}

        {!maxwidth990 && (
          <>
            <Flex data-aos="fade-down" data-aos-duration="1000" className={'item'} gap={"40px"} color={"black"} fontWeight={"medium"} fontSize={"15px"} justifyContent={"center"} alignItems={"center"} >

              <Text onClick={() => navigate('/')} cursor={"pointer"}>{t("header.aboutus")}</Text>

              <Dropdown button={<Text>{t("header.courses")}</Text>} />

              {/* <Text onClick={() => navigate('/blogs')} cursor={"pointer"}>{t("header.blogs")}</Text> */}

              <Text onClick={() => navigate('/owner')} cursor={"pointer"}>{t("header.manager")}</Text>

            </Flex>
            <Flex data-aos="fade-right" data-aos-duration="1000" justifyContent={"center"} alignItems={"center"}>
              <Menu>
                <MenuButton
                  _hover={{ color: 'primary' }}
                  bg={"none !important"}
                  color={"black"}
                  fontWeight={'medium'}
                  p={"2"}
                  as={Button}
                  leftIcon={<i className="bi bi-chevron-down"></i>}
                >
                  {t("language")}
                </MenuButton>
                <MenuList minW="60px">
                  <MenuItem
                    _hover={{ color: "white", bg: "primary" }}
                    onClick={
                      locale === "ar" ? () => { changeLocale("en"); } : () => null
                    }
                  >
                    EN
                  </MenuItem>
                  <MenuItem
                    _hover={{ color: "white", bg: "primary" }}
                    onClick={
                      locale === "en" ? () => { changeLocale("ar"); } : () => null
                    }
                  >
                    AR
                  </MenuItem>
                </MenuList>
              </Menu>
              <Image src={'/assets/vectors/language.svg'} alt="" w="20px" h="20px" />
            </Flex>
          </>
        )}
      </Flex>

      <Drawer
        isOpen={isOpen}
        placement={locale === "ar" ? "left" : "right"}
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent
          position={"relative"}
          w={"60% !important"}

        >
          <DrawerCloseButton
            mt={"10px"}
            color={"black"}
            outline={"none !important"}
            boxShadow={"none"}
            _focusVisible={{ boxShadow: "none" }}
          />

          <DrawerBody>
            <Flex
              flexDirection={"column"}
              justifyContent={"center"}
              alignItems={["center", "center", "center", "center", "center"]}
              mt="40px"
              gap={"40px"}
              color={"black"}
              fontSize={'16'}
            >
              <Text onClick={() => { navigate('/owner'); onClose() }} cursor={"pointer"} textAlign={"center"}>
                {t("header.manager")}
              </Text>

              <Dropdown button={<Text>{t("header.courses")}</Text>} />

              {/* <Text onClick={() => { navigate('/blogs'); onClose() }} cursor={"pointer"} textAlign={"center"}>
                {t("header.blogs")}
              </Text> */}

              <Flex
                justifyContent={"center"}
                w={"100%"}
                alignItems={"center"}
                gap="5px"
              >
                <Menu>
                  <MenuButton
                    bg={"none !important"}
                    color={"black"}
                    _hover={{ bg: "none" }}
                    p={"2"}
                    as={Button}
                    fontWeight={'medium'}
                    leftIcon={<i className="bi bi-chevron-down"></i>}
                  >
                    {t("language")}
                  </MenuButton>
                  <MenuList minW="60px">
                    <MenuItem
                      onClick={
                        locale === "ar"
                          ? () => { onClose(); changeLocale("en") }
                          : () => null
                      }
                    >
                      EN
                    </MenuItem>
                    <MenuItem
                      onClick={
                        locale === "en"
                          ? () => { onClose(); changeLocale("ar") }
                          : () => null
                      }
                    >
                      AR
                    </MenuItem>
                  </MenuList>
                </Menu>
                <Image src={'/assets/vectors/language.svg'} alt="" w="20px" h="20px" />
              </Flex>
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};
export default Header;
