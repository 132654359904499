import { useEffect } from 'react';
import AOS from "aos";
import "aos/dist/aos.css";

export default function AOSComponent({ children }) {
    useEffect(() => {
        setTimeout(() => {
            AOS.init({ once: true })
        }, 100);
    }, [])

    return <>{children}</>
}
