import { Box, Button, Flex, Image, Link, Stack, Text } from "@chakra-ui/react"
import { Swiper, SwiperSlide } from 'swiper/react';
import '../styles/Intro.css'
// Import Swiper styles
import 'swiper/css';

import 'swiper/css/effect-fade';
// import required modules
import { Autoplay, EffectFade } from 'swiper/modules';
import { useTranslation } from "react-i18next";
const Intro = () => {
    const { t } = useTranslation()
    return (
        <Flex position={'relative'} h={'90vh'} w={'100%'}>
            <Flex zIndex={'2'} textAlign={'center'} w={'100%'} flexDir={'column'} left={'50%'} top={'45%'} transform={'translate(-50%,-50%)'} position={'absolute'}>
                <Box data-aos="zoom-in" data-aos-duration="1000">
                    <Text mb={'50px'} color='#d2aa5c' fontWeight={'bold'} fontSize={['33px', '33px', '45px', '55px', '55px']}>{t('intro.header')}
                    </Text>
                </Box>
                <Box marginInline={['20px', '20px', '40px', '60px', '60px']} data-aos="zoom-in" data-aos-delay="100" data-aos-duration="1300">
                    <Stack spacing={'10'}>
                        <Text fontSize={['13px', '13px', '17px', '15px', '15px']} fontWeight={'medium'} color={'white'}>
                            {t('intro.heading')}
                        </Text>
                        <Box w={'100%'}>
                            <Link href="#contactus">
                                <Button w="120px" paddingBlock={["1rem", "1rem", "1.2rem", "1.2rem", "1.2rem"]} _hover={{ bg: "#b4914c" }} transition={'background 0.5s ease'} color={"white"} fontWeight={'normal'} bg={"none"} border={'2px solid #b4914c'} fontSize={["15px", "15px", "16px", "16px", "16px"]} type="submit" >
                                    {t('intro.contactUs')}
                                </Button>
                            </Link>
                        </Box>
                    </Stack>
                </Box>

            </Flex>

            <Box position={'absolute'} zIndex={'3'} opacity={'0.7'} top={'90%'} left={'50%'} transform={'translate(-50%,-50%)'}>
                <a href='#services'><i className={'chevronIcon bi bi-chevron-down'}></i></a>
            </Box>
            <Swiper noSwiping effect="fade" autoplay={{ disableOnInteraction: false, delay: 10000 }} modules={[Autoplay, EffectFade]} virtualTranslate={true} speed={2500} loop={true} className="mySwiper" slidesPerView={[1]} style={{ width: 'inherit', height: 'inherit' }}>
                <SwiperSlide>
                    <Flex w={'100%'} h={'100%'} position={'relative'}>
                        <Image alt="" src="/assets/images/passion/law-owner.jpg" position={'absolute'} w={'100%'} h={'100%'} objectFit={'cover'} />
                        <Box bg='black' position={'absolute'} zIndex={'3'} opacity={'0.7'} top={'0'} left={'0'} w={'100%'} h={'100%'}></Box>
                    </Flex>
                </SwiperSlide>
                <SwiperSlide>
                    <Flex w={'100%'} h={'100%'} position={'relative'}>
                        <Image alt="" src="/assets/images/passion/business-owners.jpg" position={'absolute'} w={'100%'} h={'100%'} objectFit={'cover'} />
                        <Box bg='black' position={'absolute'} zIndex={'3'} opacity={'0.7'} top={'0'} left={'0'} w={'100%'} h={'100%'}></Box>
                    </Flex>
                </SwiperSlide>
                <SwiperSlide>
                    <Flex w={'100%'} h={'100%'} position={'relative'}>
                        <Image alt="" src="/assets/images/passion/law-students.jpg" position={'absolute'} w={'100%'} h={'100%'} objectFit={'cover'} />
                        <Box bg='black' position={'absolute'} opacity={'0.7'} top={'0'} left={'0'} w={'100%'} h={'100%'}></Box>
                    </Flex>
                </SwiperSlide>
            </Swiper>
        </Flex>
    )
}
export default Intro