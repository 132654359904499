import { Box, Flex, Image, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tooltip, Tr } from "@chakra-ui/react";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import LocaleContext from "../../context/LocaleContext";
import '../../styles/TableView.css';
const TableView = ({ courses, slug }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { locale } = useContext(LocaleContext)
    return (
        <Flex mb={'20px'} overflowX={'auto'} borderRadius={'5px'} border={'1px solid lightgray'}>
            <TableContainer w={'100%'}>
                <Table variant='striped' colorScheme="blackAlpha" >
                    <Thead>
                        <Tr>
                            <Th fontSize={'18px'} color={'primary'}>{t('table.name')}</Th>
                            <Th fontSize={'18px'} color={'primary'}>{t('table.duration')}</Th>
                            <Th fontSize={'18px'} color={'primary'}>{t('table.teacher')}</Th>
                            <Th fontSize={'18px'} color={'primary'}>{t('table.price')}</Th>
                            <Th></Th>
                        </Tr>
                    </Thead>
                    <Tbody >
                        {courses?.map((course) => (
                            <Tr>
                                <Td>{course?.translations[0]?.name}</Td>
                                <Td>
                                    <Flex justifyContent={'center'} w={'fit-content'} alignItems={'center'} gap={'10px'} >
                                        <i class="bi bi-clock" style={{ WebkitTextStroke: '0.4px' }}></i>
                                        <Text fontWeight={'medium'} fontSize={['12px', '12px', '14px', '14px', '14px']}>
                                            {course?.hours} {t('hours')}
                                        </Text>
                                    </Flex>
                                </Td>
                                <Td>
                                    <Flex justifyContent={'start'} w={'100%'} alignItems={'center'} gap={'5px'}>
                                        {course?.teachers?.map((teacher) => (
                                            <Tooltip label={teacher?.translations[0]?.name}>
                                                <Box w={'40px'} h={'40px'}>
                                                    <Image textAlign={'center'} borderRadius={'50%'} w={'100%'} objectFit={'cover'} h={'100%'} mixBlendMode={'color-burn'} src={`${process.env.REACT_APP_BASE_URL}/uploads/teacher/${teacher?.image}`} alt="" />
                                                </Box>
                                            </Tooltip>
                                        ))}
                                    </Flex>
                                </Td>
                                <Td>
                                    <Text fontSize={'14px'}>
                                        {course?.price} <span style={{ fontSize: '12px' }}>{t('money')}</span>
                                    </Text>
                                </Td>
                                <Td _hover={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => navigate(`/program/${slug}/course/${course?.slug}`)}>
                                    <Flex justifyContent={'center'} w={'fit-content'} alignItems={'center'} gap={'10px'} >
                                        <Text>{t('table.details')}</Text>
                                        <i style={{ fontSize: '12px', color: '#b4914c' }} className={`bi bi-chevron-${locale === 'en' ? 'right' : 'left'}`} ></i>
                                    </Flex>
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </TableContainer >
        </Flex>
    )
}
export default TableView