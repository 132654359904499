import { Box, Flex, Image, Text } from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import TableView from "../components/courses/TableView";
import LocaleContext from "../context/LocaleContext";
import { COURSE } from "../services/course";
import Pagination from "../components/courses/Pagination";

const Program = () => {
    const [courses, setCourses] = useState();
    const [program, setProgram] = useState();
    const [page, setPage] = useState(1);
    const { locale } = useContext(LocaleContext)
    const { slug } = useParams();
    const [totalItems, setTotalItems] = useState();
    let onPageChange;
    const pageChanged = (page) => { setPage(page); if (onPageChange) onPageChange(page); };

    useEffect(() => {
        COURSE.getCourses(slug, page, locale).then(res => {
            setCourses(res.data.data)
            setProgram(res.data.data[0]?.programs[0])
            setTotalItems(res.data.meta.totalItems)
        })
    }, [locale, page, slug])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            <Flex mb={'30px'} flexDir={'column'} minH={'80vh'} data-aos="fade-down" data-aos-delay="400" data-aos-duration="1000" flexWrap={'wrap'} gap={'10px'} alignItems={'center'}>
                <Box w={'100%'} h={'300px'} position={'relative'}>
                    <Box bg='linear-gradient(#000000 60%,#b4914c)' position={'absolute'} opacity={'0.6'} top={'0'} left={'0'} w={'100%'} h={'100%'}></Box>
                    <Text position={'absolute'} color={'primary'} top={'50%'} left={'50%'} transform={'translate(-50%,-50%)'} fontWeight={'bold'} fontSize={'50px'} textAlign={'center'}>{program?.translations[0]?.name}</Text>
                    <Image objectFit={'cover'} w={'100%'} h={'100%'} src={`${process.env.REACT_APP_BASE_URL}/uploads/program/${program?.image}`} alt="" />
                </Box>
                <Flex p={'40px'} gap={'30px'} flexDir={'column'} justifyContent={'start'} alignItems={'start'} w='100%'>
                    <Box dangerouslySetInnerHTML={{ __html: program?.translations[0]?.description }}>
                    </Box>
                </Flex>
            </Flex>
            <Box paddingInline={'40px'}>
                <TableView courses={courses} slug={slug} />
            </Box>

            <Pagination pageSize={10} totalItems={totalItems} onPageChange={pageChanged} currentPage={page} />

        </>
    )
}
export default Program;
