import { Box, Flex, Image, Text } from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import LocaleContext from "../context/LocaleContext";
import { COURSE } from "../services/course";
import { useTranslation } from "react-i18next";

const Course = () => {
    const [data, setData] = useState();
    const { locale } = useContext(LocaleContext)
    const { courseSlug } = useParams()
    const { t } = useTranslation();
    useEffect(() => {
        COURSE.getCourseBySlug(courseSlug, locale).then(res => {
            setData(res.data.data)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locale])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            <Flex mt={'20px'} minH={'70vh'} mb={'10px'} gap={'30px'} paddingInline={['0px', '0px', '60px', '200px', '200px']} flexDir={'column'} justifyContent={'center'} alignItems={'center'} w='100%'>
                <Text fontWeight={'bold'} fontSize={'22px'}>{data?.translations[0].name}</Text>

                <Box maxW={'600px'} w={'100%'}>
                    <Image alt="" borderRadius={'3px'} w={'100%'} maxH={'400px'} objectFit={'contain'} src={`${process.env.REACT_APP_BASE_URL}/uploads/course/${data?.image}`} />

                    <Flex flexDir={['column', 'column', 'row', 'row', 'row']} mt={'10px'} justifyContent={'center'} alignItems={'center'} gap={'10px'}>
                        <Flex justifyContent={'center'} w={'fit-content'} alignItems={'center'} gap={'10px'} >
                            <Text color={'primary'} fontWeight={'medium'} fontSize={['10px', '10px', '12px', '12px', '12px']}>
                                {t('table.duration')} :
                            </Text>
                            <i class="bi bi-clock" style={{ WebkitTextStroke: '0.4px', color: 'gray' }}></i>
                            <Text color={'gray'} fontWeight={'medium'} fontSize={['12px', '12px', '14px', '14px', '14px']}>
                                {data?.hours} {t('hours')}
                            </Text>
                        </Flex>
                        <Box borderLeft={'1px solid lightgray'} w={'2px'} h={['10px', '10px', '20px', '20px', '20px']} />
                        <Flex justifyContent={'center'} w={'fit-content'} alignItems={'center'} gap={'10px'} >
                            <Text color={'primary'} fontWeight={'medium'} fontSize={['10px', '10px', '12px', '12px', '12px']}>
                                {t('table.teacher')} :
                            </Text>
                            <i class="bi bi-person" style={{ WebkitTextStroke: '0.4px', color: 'gray' }}></i>
                            <Text color={'gray'} fontWeight={'medium'} fontSize={['12px', '12px', '14px', '14px', '14px']}>
                                {data?.teachers[0].translations[0].name}
                            </Text>
                        </Flex>
                        <Box borderLeft={'1px solid lightgray'} w={'2px'} h={['10px', '10px', '20px', '20px', '20px']} />
                        <Flex justifyContent={'center'} w={'fit-content'} alignItems={'center'} gap={'10px'} >
                            <Text color={'primary'} fontWeight={'medium'} fontSize={['10px', '10px', '12px', '12px', '12px']}>
                                {t('table.price')} :
                            </Text>
                            <Text color={'gray'} fontSize={['12px', '12px', '14px', '14px', '14px']}>
                                {data?.price} <span style={{ fontSize: '12px' }}>AED</span>
                            </Text>
                        </Flex>
                    </Flex>
                </Box>
            </Flex>
            <Box borderTop={'1px solid lightgray'} w={'100%'} />
            <Flex p={'40px'} gap={'30px'} flexDir={'column'} justifyContent={'start'} alignItems={'start'} w='100%'>
                <Text color={'primary'} fontWeight={'medium'} fontSize={'30px'}>
                    {t('table.details')}
                </Text>
                <Box dangerouslySetInnerHTML={{ __html: data?.translations[0].description }}>
                </Box>
            </Flex>
        </>
    )
}
export default Course;